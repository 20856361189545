<template>
  <div class="serve-box"  @scroll="handleScroll">
    <div class="serve-box-header">
      <p>联合全球优势资源</p>
      <p>共创品牌体验价值</p>
      <div class="dev-line"></div>
    </div>
    <div class="serve-box-body">
      <img src="@/assets/customer/company.png" alt="" class="comapnyimg">
    </div>
    <div class="bottom-icon">
      <!--底部箭头-->
      <img src="@/assets/bottomicon.png" class="bottom-img" @click="clickBottom" alt="" />
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
    }
  },
  methods:{
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当距离底部50px内滚动时，进行处理
      if (scrollHeight === (scrollTop + clientHeight)) {
        // 进入下一页或加载更多数据的逻辑
        this.$router.push("/customername");
      }
    },
    clickBottom(){
      this.$router.push("/customername");
    },
  }
}
</script>
<style scoped>
.serve-box{
  background-image: url("../assets/moduce-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 50px;
  height: 100vh;
  overflow-y: auto;
}
.serve-box-header{
  font-family: HYLingXinJ;
  font-weight: normal;
  font-size: 28px;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-align: left;
  padding-left: 30px;
}
.serve-box-body{
  padding: 1rem;
}
.comapnyimg{
  width: 100%;
}
.dev-line{
  width: 30px;
  height: 2px;
  background: #fff;
  margin: 5px 0 20px 0;
}
.bottom-icon{
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.bottom-img{
  width: 50px;
}
</style>
