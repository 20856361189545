<template>
  <div class="moduce-box" @scroll="handleScroll">
    <div @click="goBack" style="padding-top: 20px;width: 50px;height: 50px;display: flex;color: #fff">
      <img src="@/assets/arrow.png" alt="" class="arrow-icon">
    </div>
      <div class="top">
        <div>
          <div class="top-title">{{title}}</div>
          <div class="dev-line"></div>
          <div class="top-text">
            <div class="top-text-one">{{topTextOne}}</div>
            <div class="con-title" v-show="topPointText">
              <div class="top-point"></div>
              <div>{{topPointText}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
          <div class="content-main" @click="clickBtn" v-if="mainProductImg">
            <img :src="mainProductImg" class="btn-img"  alt="" />
            <img src="@/assets/btn-point.png" class="point-img"  alt="" />
            <template v-if="title === '运动赛事' ">
              <img v-show="showLine" src="@/assets/line.png" class="line-img"  alt="" />
            </template>
            <template v-else-if="title === '体育运动' ">
              <img v-show="showLine" src="@/assets/m-tyyd/line.png" class="line-img tyyd-line-img"  alt="" />
            </template>
            <template v-else-if="title === '周年庆典' ">
              <img v-show="showLine" src="@/assets/m-znqd/line.png" class="line-img znqd-line-img"  alt="" />
            </template>
            <template v-else-if="title === '团队活动' ">
              <img v-show="showLine" src="@/assets/m-tdhd/line.png" class="line-img tdhd-line-img"  alt="" />
            </template>
            <template v-else-if="title === '员工工服' ">
              <img v-show="showLine" src="@/assets/m-yggf/line.png" class="line-img yggf-line-img"  alt="" />
            </template>
            <template v-else-if="title === '校服定制' ">
              <img v-show="showLine" src="@/assets/m-xfdz/line.png" class="line-img xfdz-line-img"  alt="" />
            </template>
            <template v-else-if="title === '公益事业' ">
              <img v-show="showLine" src="@/assets/m-gysy/line.png" class="line-img gysy-line-img"  alt="" />
            </template>
          </div>
          <div class="showproduct">
            <img :src="item.src" alt="" v-for="(item,index) in showProductList" :key="index" class="btn-img show-img" >
          </div>
          <div class="bottom-icon">
            <!--底部箭头-->
            <img src="@/assets/bottomicon.png" class="bottom-img" @click="clickBottom" alt="" />
          </div>
      </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      title:'',
      clcikAll:false,
      topTextOne:'',
      topPointText:'',
      showLine:false,
      mainProductImg:'',
      showProductList:[]
    }
  },
  mounted() {
    console.log(this.$route.query,'text')
    this.title=this.$route.query.text
    this.clcikAll=this.$route.query.clcikAll
    this.getMainImg()
  },
  methods:{
    goBack(){
      this.$router.push("/sevenmoduce");
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当距离底部50px内滚动时，进行处理
      if (scrollHeight === (scrollTop + clientHeight)) {
        // 进入下一页或加载更多数据的逻辑
        if(this.clcikAll && this.showProductList.length >0){
          this.$router.push("/customercompany");
        }

      }
    },
    getMainImg(){
      const name=this.title
      if(name === '运动赛事'){
        this.mainProductImg=require("@/assets/m-ydss/mainimg.png")
        this.topTextOne="专业装备提升赛场表现"
        this.topPointText="沙特代表队 - 24年巴黎奥运会"
      }
      else if(name === '体育运动'){
        this.mainProductImg=require("@/assets/m-tyyd/mainimg.png")
        this.topPointText="Bad Five. 汰渍"
      }
      else if(name === '周年庆典'){
        this.mainProductImg=require("@/assets/m-znqd/mainimg.png")
        this.topTextOne="优质装备保障庆典游行"
        this.topPointText="中华人民共和国成立70周年"
      }
      else if(name === '团队活动'){
        this.mainProductImg=require("@/assets/m-tdhd/mainimg.png")
        this.topTextOne="展现团队风采 共创企业活力新篇章"
        this.topPointText="欢乐谷多彩跑/万科城市乐跑赛/万科“万李行”"
      }
      else if(name === '员工工服'){
        this.mainProductImg=require("@/assets/m-yggf/mainimg.png")
        this.topTextOne=""
        this.topPointText="德邦快递"
      }
      else if(name === '校服定制'){
        this.mainProductImg=require("@/assets/m-xfdz/mainimg.png")
        this.topTextOne="桃李无言 下自成蹊 全国多家定制校服"
      }
      else if(name === '公益事业'){
        this.mainProductImg=require("@/assets/m-gysy/mainimg.png")
        this.topTextOne="投身公益事业发展 践行企业社会责任"
        this.topPointText="可口可乐/ WABC艺途公益基金会/ COSTA联合环保T恤/新冠疫情捐赠物资”"
      }

    },
    clickBottom(){
      if(this.clcikAll && this.showProductList.length >0){
        this.$router.push("/customercompany");
      }
    },
    clickBtn(){
      this.showLine=true
      const name=this.title
        if(name === '运动赛事'){
          this.showProductList=[
            {
              src: require("@/assets/m-ydss/pro1.png"),
            },
            {
              src: require("@/assets/m-ydss/pro2.png"),
            },
            {
              src: require("@/assets/m-ydss/pro3.png"),
            },
            {
              src: require("@/assets/m-ydss/pro4.png"),
            },
          ]
        }
        else if(name === '体育运动'){
          this.showProductList=[
            {
              src: require("@/assets/m-tyyd/pro1.png"),
            },
            {
              src: require("@/assets/m-tyyd/pro2.png"),
            },
          ]
        }
        else if(name === '周年庆典'){
          this.showProductList=[
            {
              src: require("@/assets/m-znqd/pro1.png"),
            },
            {
              src: require("@/assets/m-znqd/pro2.png"),
            },
          ]
        }
        else if(name === '团队活动'){
          this.showProductList=[
            {
              src: require("@/assets/m-tdhd/pro1.png"),
            },
            {
              src: require("@/assets/m-tdhd/pro2.png"),
            },
          ]
        }
        else if(name === '员工工服'){
          this.showProductList=[
            {
              src: require("@/assets/m-yggf/pro1.png"),
            },
            {
              src: require("@/assets/m-yggf/pro2.png"),
            },
          ]
        }
        else if(name === '校服定制'){
          this.showProductList=[
            {
              src: require("@/assets/m-xfdz/pro1.png"),
            },
            {
              src: require("@/assets/m-xfdz/pro2.png"),
            },
            {
              src: require("@/assets/m-xfdz/pro3.png"),
            },
          ]
        }
        else if(name === '公益事业'){
          this.showProductList=[
            {
              src: require("@/assets/m-gysy/pro1.png"),
            },
            {
              src: require("@/assets/m-gysy/pro2.png"),
            },
          ]
        }
    }

  }
}
</script>
<style scoped>
.moduce-box{
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-image: url("../assets/m-ydss/all-bg.png");
}

.top{
  background-image: url("../assets/m-ydss/top-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 80px;
}
.top-title{
  font-family: HYLingXinJ;
  font-size: 22px;
  letter-spacing: 2px;
  color: #FFFFFF;
  text-align: left;
  padding-left: 50px;
}
.top-point{
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
}
.top-text{
  font-family: FZLTHJW--GB1-0, FZLTHJW--GB1-0;
  font-size: 16px;
  color: #FFFFFF;
  text-align: left;
  margin-top: 8px;
  letter-spacing: 2px;
}
.top-text-one{
  padding-left: 50px;
}
.dev-line{
  width: 30px;
  height: 2px;
  background: #fff;
  margin-top: 5px;
  margin-left: 50px;
}
.content{
  padding:1rem 1.5rem 0 1.5rem;
}
.content-main{
  position: relative;
  animation: slide-in-left 2s forwards;
  opacity: 0;
}
@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;

  }
}
.point-img{
  width: 90px;
  height: 90px;
  position: absolute;
  left: 38%;
  bottom: -40px;
  z-index: 99;
}
.line-img{
  position: absolute;
  left: 62%;
  bottom: -70px;
  height: 120px;
  width: 50px;
  animation: slide-in 2s forwards;
  animation-delay: 1s;
  opacity: 0;
}
.znqd-line-img{
  left: 40%;
  bottom: -5rem;
  z-index: 1;
  width: 6rem;
  height: 6rem;
}
.tyyd-line-img{
  left: 28%;
  bottom: -6rem;
  z-index: 1;
  width: 11rem;
  height: 8rem;
}
.tdhd-line-img{
  left: 70%;
  bottom: -5rem;
  z-index: 1;
  width: 3rem;
  height: 7rem;
}
.yggf-line-img{
  left: 57%;
  width: 4rem;
}
.xfdz-line-img{
  width: 5rem;
  height: 10rem;
  left: 47%;
}
.gysy-line-img{
  left: 25%;
  width: 10rem;
  bottom: -5rem;
  height: 11rem;
}
.con-title{
  font-family: FZLTCHJW--GB1-0, FZLTCHJW--GB1-0;
  font-weight: normal;
  font-size: 12px;
  color: #FFFFFF;
  text-align: left;
  padding: 30px 30px 0 30px;
  display: flex;
  align-items: center;
}
.showproduct{
  margin-top: 50px;
  min-height: 300px;
}
.btn-img{
  width: 100%;
  height: 100%;
}
.show-img{
  animation: slide-in 2s forwards;
  animation-delay: 1s;
  opacity: 0;
}
@keyframes slide-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;

  }
}
.arrow-icon{
  width: 100%;
  height: 100%;
}
.bottom-icon{
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.bottom-img{
  width: 50px;
}
</style>
