<template>
  <div class="brandintroduce" @scroll="handleScroll">
    <img src="@/assets/brandintroduce.png" alt="" class="brandimg">
    <div class="bottom-icon">
      <img src="@/assets/bottomicon.png" class="bottom-img" @click="clickImg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  methods:{
    clickImg(){
      this.$router.push('/brandhistroy')
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当距离底部50px内滚动时，进行处理
      if (scrollHeight === (scrollTop + clientHeight)) {
        // 进入下一页或加载更多数据的逻辑
        this.$router.push('/brandhistroy')
      }
    },
  }
}
</script>

<style scoped>
.brandintroduce {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.brandimg{
  width: 100%;
}
.bottom-icon{
  display: flex;
  justify-content: center;
  background-color: #E11629;
}
.bottom-img{
  width: 70px;
  margin-bottom: 20px;
}
img {
  display: block; /* 去除图片间的间隙 */
  max-width: 100%; /* 确保图片不会超过其容器宽度 */
}
</style>
