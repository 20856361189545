<template>
  <div class="info-box">
    <div class="top-box">
      <img src="@/assets/info-logo.png" alt="" class="info-logo">
      <div class="top-text">请填写您的信息</div>
    </div>
    <div class="content">
      <div class="flex-dev">
        <div class="dev-label">* 姓名</div>
        <input type="text" placeholder="请输入您的姓名" v-model="params.name" class="dev-input">
      </div>
      <div class="flex-dev">
        <div class="dev-label">* 性别</div>
        <div class="dev-select">
          <div style="color: #757575;font-size: 14px;line-height: 40px">
            {{params.sex? params.sex === '1' ? '男':'女' :'请选择您的性别'}}
            <div class="dev-choose" v-if="showSelect">
              <div class="dev-sex-man" @click="clickSex('1')">男</div>
              <div class="dev-sex-woman" @click="clickSex('2')">女</div>
            </div>
          </div>
          <div class="select-right" @click="clickSelect">
            <div class="dev-line"></div>
            <img src="@/assets/info-select.png" alt="" class="select-img">
          </div>

        </div>
      </div>
      <div class="flex-dev">
        <div class="dev-label">* 企业名称</div>
        <input type="text" placeholder="请输入您的企业名称" v-model="params.companyName" class="dev-input">
      </div>
      <div class="flex-dev">
        <div class="dev-label">* 企业邮箱</div>
        <input type="text" placeholder="请输入您的企业邮箱" v-model="params.companyEmail" class="dev-input">
      </div>
      <div class="flex-dev">
        <div class="dev-label">* 联系方式</div>
        <input type="text" placeholder="请输入您的联系方式" v-model="params.phone" class="dev-input">
      </div>
    </div>
    <div class="bottom-box">
      <div class="submit-btn" @click="submitData">提交</div>
    </div>
    <span class="error-box" v-show="showError">
     {{errMsg}}
    </span>
  </div>
</template>
<script>
import axios from 'axios';
export default {
    data(){
      return{
        showSelect:false,
        showError:false,
        errMsg:'请完善好信息在提交',
        params:{
          name:'',
          sex:'',
          phone:'',
          companyName:'',
          companyEmail:'',
          secret:'a3df3371b674484ea37da3225cca3837',
        }
      }
    },
  methods:{
    clickSelect(){
      this.showSelect=!this.showSelect
    },
    clickSex(sex){
      this.params.sex=sex
      this.showSelect=false
    },
      submitData(){
        axios.defaults.baseURL='/api/'
        if( this.params.name === ''
            || this.params.sex === ''
            || this.params.sex === ''
            || this.params.phone === ''
            || this.params.companyName === ''
            || this.params.companyEmail === ''
        ){
          this.showError=true
          setTimeout(()=>{
            this.showError=false
          },2000)
          return;
        }
        axios.post('openApiRest/saveCustomerInfoCollection',this.params)
            .then(response => {
              if(response.data && response.data.result){
                this.errMsg=response.data.msg
                this.showError=true
                setTimeout(()=>{
                  this.showError=false
                  this.$router.push("/home")
                },2000)
              }else{
                this.errMsg=response.data.msg || "请求失败"
                this.showError=true
                setTimeout(()=>{
                  this.showError=false
                },2000)
              }
            })
            .catch(error => {
              console.error('There was an error!', error);
            });
      }
  }
}
</script>
<style scoped>
  .info-box{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 20px;
    background-image: url("../assets/info-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
  }
  .top-box{
    display: flex;
    flex-direction: column;
    padding-top: 50px;

  }
  .top-text{
    text-align: center;
    margin: 30px 0;
    color: #FFFFFF;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .info-logo{
    width: 80px;
    height: 35px;
    margin-left: 50px;
  }
  .flex-dev{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;
  }
  .dev-label{
    width: 75%;
    margin-bottom: 4px;
  }
  .dev-input{
    width: 75%;
    height: 40px;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #E6E6E6;
    padding-left: 5px;
  }
  .dev-select{
    width: 75%;
    height: 40px;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #E6E6E6;
    padding-left: 5px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .dev-choose{
    position: absolute;
    width: 80%;
    text-align: center;
    color: #000;
  }
  .dev-sex-man{
    background: #ddd;
    border-bottom: 1px solid #fff;
  }
  .dev-sex-woman{
    background: #ddd;
  }
.select-right{
  display: flex;
  align-items: center;
}

.dev-line{
  width: 1px;
  height: 32px;
  background-color: #ccc;
}
.select-img{
  width: 10px;
  height: 8px;
  margin: 0 15px;
}
.bottom-box{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit-btn{
  width: 80%;
  height: 55px;
  background: #A00F1E;
  border-radius: 65px 65px 65px 65px;
  color: #fff;
  line-height: 55px;
  font-family: FZLTCHJW--GB1-0;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 2px;
  margin-top: 30px;
}
.error-box{
  background-color: #666;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 40%;
  left: 30%;
}
input {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
input:focus {
  outline: none;

}
</style>
