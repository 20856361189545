<template>
  <div>
    <div class="tuangou">
      <div class="top-title">
        <div class="top-en">GROUP</div>
        <div class="top-en">PURCHASE</div>
        <div class="top-en">SERVICE</div>
        <div class="top-yewu">团购业务</div>
        <div class="top-bottom">为携手共赢而生</div>
      </div>
      <div class="bottom-title">
        <div>强大的科技实力</div>
        <div>优质的设计师资源</div>
        <div>严苛的品质把控</div>
      </div>
    </div>
    <div class="btn">
      <img src="@/assets/tuangou-btn.png" class="btn-img" @click="showModuce"></img>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{

      }
    },
    mounted() {
    },
    methods:{
      showModuce(){
        this.$router.push('/sevenmoduce')
      },
    }
  }
</script>
<style scoped>
.tuangou {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 20px;
  background-image: url("../assets/tuangou-bg.png");
  position: relative;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中显示 */
  background-repeat: no-repeat; /* 背景图片不重复 */
}
.top-title{
  font-weight: normal;
  font-size: 24px;
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
}
.top-en{
  font-size: 40px;
  font-family: HemiHeadEb-Regular;
  font-weight: normal;
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 1px #FFFFFF;
  letter-spacing: 2px;
  color: #AA101F;
}

.top-yewu{
  font-family: HYLingXinJ;
  font-size: 38px;
  margin-bottom: 4px;
  margin-top: 10px;
  color: #FFFFFF;
}
.top-bottom{
  font-family: FZLTCHJW--GB1-0;
  font-size: 13px;
  letter-spacing: 2px;
  padding-left: 4px;
  margin-top: 10px;
  color: #FFFFFF;
}
.bottom-title{
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  text-align: right;
  position: absolute;
  right: 2rem;
  bottom: 5rem;
  letter-spacing: 1px;
}
.btn{
  background-color: #db1528;
}
.btn-img{
  width: 105px;
  height: 105px;
}
</style>
