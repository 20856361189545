<template>
  <div class="serve-box"  @scroll="handleScroll">
    <div class="serve-box-body">
      <img src="@/assets/customer/name.png" alt="" class="comapnyimg">
      <div class="bottom-icon">
        <img src="@/assets/bottomicon.png" class="bottom-img" @click="clickBottom" alt="" />
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
    }
  },
  methods:{
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当距离底部50px内滚动时，进行处理
      if (scrollHeight === (scrollTop + clientHeight)) {
        // 进入下一页或加载更多数据的逻辑
        this.$router.push("/servecustomers");

      }
    },
    clickBottom(){
      this.$router.push("/servecustomers");
    },
  }
}
</script>
<style scoped>
.serve-box{
  height: 100vh;
  overflow-y: auto;
}

.comapnyimg{
  display: block;
  width: 100%;
}

.bottom-icon{
  display: flex;
  justify-content: center;
  background-color: #de1629;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.bottom-img{
  width: 50px;
  display: block;
}
</style>
