<template>
  <div>
    <div class="tuangou">
      <div class="top-title">
        <div class="top-en">GROUP</div>
        <div class="top-en">PURCHASE</div>
        <div class="top-en">SERVICE</div>
        <div class="top-yewu">团购业务</div>
        <div class="top-bottom">为携手共赢而生</div>
      </div>
      <div class="moduce">
        <div v-for="(item,index) in list" :key="index" class="moduce-dev" >
          <img :src="item.src" class="btn-img1" :class="['btn'+index]" alt="" @click="clickBtn(item.text,index)"></img>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      storeArr:[],
      allClick:false,
      list:[
        {
          src: require('@/assets/btn-znqd.png') ,
          text:'周年庆典'
        },
        {
          src:require('@/assets/btn-ydss.png'),
          text:'运动赛事'
        },
        {
          src: require('@/assets/btn-tdhd.png') ,
          text:'团队活动'
        },
        {
          src:require('@/assets/btn-gysy.png') ,
          text:'公益事业'
        },
        {
          src:require('@/assets/btn-gongfu.png') ,
          text:'员工工服'
        },
        {
          src:require('@/assets/btn-xfdz.png') ,
          text:'校服定制'
        },
        {
          src:require('@/assets/btn-tyhd.png') ,
          text:'体育运动'
        },
      ]
    }
  },
  mounted() {
    this.storeArr = new Array(7).fill(false);
    if(sessionStorage.getItem('storeArr')){
      this.storeArr=JSON.parse(sessionStorage.getItem('storeArr'))
    }
  },
  methods:{
    clickBtn(item,index){
      //这里是需要所有模块都被点击了之后，下一个页面才可以进入customercompany
      this.storeArr[index] = true;
      sessionStorage.setItem('storeArr',JSON.stringify(this.storeArr));
      // 检查是否所有模块都已被点击
      const allClicked = this.storeArr.every(Boolean);
      if (allClicked) {
        this.allClick=true
      }
      this.$router.push({
        path:'/moduceintroduce',
        query:{
          text:item,
          clcikAll:this.allClick
        }
      })
    }

  }
}
</script>
<style scoped>
.tuangou {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-image: url("../assets/moduce-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}
.top-title{
  font-family: HemiHeadEb-Regular;
  font-weight: normal;
  font-size: 24px;
  text-align: left;
  padding-top: 40px;
  padding-left: 20px;
  animation: zoom-in 2s forwards;
  opacity: 0;
}
.top-en{
  font-size: 40px;
  font-family: HemiHeadEb-Regular;
  font-weight: normal;
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 1px #FFFFFF;
  letter-spacing: 2px;
  color: #AA101F;
}

.top-yewu{
  font-family: HYLingXinJ;
  font-size: 38px;
  margin-bottom: 4px;
  margin-top: 10px;
  color: #FFFFFF;
}
.top-bottom{
  font-family: FZLTCHJW--GB1-0;
  font-size: 13px;
  letter-spacing: 2px;
  padding-left: 4px;
  margin-top: 10px;
  color: #FFFFFF;
}
.bottom-icon{
  background-color: #db1528;
  padding-bottom: 10px;
  padding-top: 10px;
}

.btn-img{
  width: 50px;
}
.btn-img1{
  width: 150px;
  height: 150px;
}

.moduce{
  height: 70%;
}
.moduce-dev{
  position: relative;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.5);
    color: #ddd;
    opacity: 0;
  }
  0% {
    transform: scale(1.5);
    color: #000;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    color: #fff;
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;

  }
}
.btn0{
  position: absolute;
  top: -50px;
  right: 5px;
  animation: flipInY 2s infinite;
}
.btn1{
  position: absolute;
  top: 35px;
  left: 4%;
  animation: flipInY 2s infinite;
}
.btn2{
  width: 160px;
  height: 160px;
  position: absolute;
  top: 100px;
  left: 42%;
  animation: flipInY 2s infinite;
}
.btn3{
  width: 170px;
  height: 170px;
  position: absolute;
  top: 195px;
  left: 2%;
  animation: flipInY 2s infinite;

}
.btn4{
  width: 100px;
  height: 100px;
  position: absolute;
  top: 250px;
  right: 5px;
  animation: flipInY 2s infinite;
}
.btn5{
  width: 175px;
  height: 175px;
  position: absolute;
  top: 330px;
  left: 40%;
  animation: flipInY 2s infinite;

}
.btn6{
  width: 120px;
  height: 120px;
  position: absolute;
  top: 385px;
  left: 6%;
  animation: flipInY 2s infinite;
}
@keyframes flipInY {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(360deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}


</style>
