<template>
  <div class="serve-box" @scroll="handleScroll">
    <div class="serve-box-header">
      积极投身公益事业
    </div>
    <div class="dev-line"></div>
    <div class="serve-box-body">
      <div class="dev-flex dev-flex-one">
        <div class="dev-item">
          <img src="@/assets/serveone.png" class="serveimg" alt="" />
        </div>
        <div class="dev-item">
          <img src="@/assets/servetwo.png" class="serveimg" alt="" />
        </div>
      </div>
      <div class="dev-flex" style="min-height: 280px">
        <div class="dev-item dev-text">
          成为中国妇女发展基金会的战略合作伙伴；
          关注救灾工作，积极为汶川、鲁甸等地震灾区捐赠物资；
          资助希望小学、关爱艾滋孤儿；
          参与援建“风雨操场”等多个体育教育公益项目；
          联合可国可乐中国参与“24小时净水”项目，为一线提供环保救援服。
        </div>
        <div class="dev-item dev-three">
          <img src="@/assets/servethree.png" class="serveimg serve-psimg" alt="" />
        </div>
      </div>
      <div class="dev-last">
        <img src="@/assets/servefour.png" class="serveimg" alt="" />
      </div>
    </div>
    <div class="bottom-icon" ref="bottomElement">
      <!--底部箭头-->
      <img src="@/assets/bottomicon.png" class="bottom-img" id="bottm-arrow" @click="clickBottom" alt="" />
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      title:'',
    }
  },

  methods:{
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当距离底部50px内滚动时，进行处理
      if (scrollHeight === (scrollTop + clientHeight)) {
        // 进入下一页或加载更多数据的逻辑
        this.$router.push("/getinformation");
      }
    },

    clickBottom(){
      this.$router.push("/getinformation");
    },
  }
}
</script>
<style scoped>
.serve-box{
  background-image: url("../assets/moduce-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 50px;
  height: 100vh;
  overflow-y: auto;
}
.serve-box-header{
  font-family: HYLingXinJ;
  font-weight: normal;
  font-size: 28px;
  color: #FFFFFF;
  letter-spacing: 2px;
  text-align: left;
  padding-left: 20px;

}
.dev-line{
  width: 30px;
  height: 2px;
  background: #fff;
  margin: 5px 0 20px 20px;
}
.dev-flex{
  display: flex;
}
.dev-flex-one{
  padding-right: 20px;
}
.dev-item{
  width: 100%;
}
.dev-text{
  color: #FFFFFF;
  font-size: 14px;
  padding: 20px;
}
.dev-last{
  padding-left: 20px;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.dev-three{
  position: relative;
}
.serveimg{
  width: 100%;
}
.serve-psimg{
  height: 280px;
  position: absolute;
  right: 0;
  top: -20px;
}
.bottom-icon{
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.bottom-img{
  width: 50px;
}
</style>
