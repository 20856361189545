<template>
  <div class="box" ref="container" @scroll="boxScroll">
    <div @click="stopFlash">
      <div class="title">激扬初心 砥砺前行</div>
      <div class="dev-line"></div>
      <div style="width: 100%  ;display: flex;justify-content: space-between; flex-wrap: wrap;padding: 20px 0" class="container"  >
            <div style="width: 40%;color: #fff;margin-top: 5px" >
                <!--左边一列-->
              <div v-for="(item,index) in list2 " :key="index" :style="index === 0 ? 'margin-top: 40px' : 'margin-top: 4px'"
                   :class="['list2class-' + index]"
                   style="min-height: 50px"
              >
                <div>
                  <div style="display: flex;position: relative" >
                    <div class="year-line"></div>
                    <div class="year-num">{{item.year}}</div>
                    <div class="item-light light-l"></div>
                  </div>
                  <div class="item-text" >{{item.text}}</div>
                </div>
              </div>
            </div>

        <div style="width: 40%;color: #fff;" >
                <!--右边一列-->
          <div  v-for="(item,index) in list " :key="index" style="margin-top: 5px">
            <div>
              <div style="display: flex ; position: relative" >
                <div class="item-light light-r" ></div>
                <div class="year-num">{{item.year}}</div>
                <div style="margin-left: 5px" class="year-line"></div>
              </div>
              <div class="item-text">{{item.text}}</div>
            </div>
          </div>
        </div>
        <div  class="text-last"  v-if="showLast">
          上海李宁中心正式投入使用，李宁“三总部两基地”战略布局完成
        </div>
        <div class="dev-last" v-show="isShowIcon">
          <img src="@/assets/bottomicon.png" alt=""  class="bot-img"@click="goURL">
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      list:[],
      list2:[],
      isShowIcon:false,
      showLast:false,
      isShowFlash:false,
      flagNum:0,
    }
  },
  computed:{
  },
  mounted() {
    this.getList()
  },
  methods:{
    boxScroll(event) {
      let num=this.flagNum
      const {scrollTop, clientHeight, scrollHeight} = event.target;
      // 当距离底部50px内滚动时，进行处理
      if (scrollHeight === (scrollTop + clientHeight)) {
        // 进入下一页或加载更多数据的逻辑
        if (this.showLast) {
          this.flagNum=1
          num++
          console.log(num,'进入下页面2')
          if(num>=2){
            console.log(num,'进入下页面2')
            setTimeout(()=>{
              this.$router.push("/tuangouintroduce");
              this.flagNum=0
            },2000)
          }

        }
      }
    },
    stopFlash(){
      this.isShowFlash=true
    },
    getList(){
      // 设置定时器，每秒增加计数
      let count =-1
      let timer = setInterval(()=>{
        if(this.isShowFlash){
          console.log('终止动画')
          clearInterval(timer);
          this.list=[]
          this.list2=[]
          this.$nextTick(()=>{
            this.list2=[
              {
                year:1992,
                text:"1992年起，连续四届成为奥运会中国代表团指定领奖装备合作伙伴",
              },
              {
                year:1998,
                text:"在广东佛山成立国内首家运动服装与鞋产品设计开发中心",
              },
              {
                year:2008,
                text:"在香港联交所主板成功上市，成为内地首家在香港上市的体育用品公司",
              },
              {
                year:2012,
                text:"成为CBA官方战略合作伙伴",
              },
              {
                year:2018,
                text:"对外宣布采取“单品牌、多品类、多渠道”的发展策略",
              },
              {
                year:2019,
                text:"李宁自主科技矩阵中革命性的一环“李宁䨻“轻弹科技平台正式发布",
              },
              {
                year:2021,
                text:"“悟创吾意”中国李宁2021秋冬潮流发布举行，引发热议",
              },
              {
                year:2021,
                text:"“悟·行”主题大秀在林芝举行",
              },
              {
                year:2022,
                text:"李宁飞电3ULTRA助力签约运动员获得柏林马拉松男子组第三名，中国运动品牌首登世界六大马拉松赛事领奖台",
              },


            ]
            this.list=[
              {
                year:1990,
                text:"从广东三水起步，“李宁 牌”伴随第十一届亚运会 圣火传遍全国",
              },
              {
                year:1993,
                text:'迁址至北京',
              },
              {
                year:2002,
                text:'确立全新品牌定位，提出“一切皆有可能”的品牌口号',
              },
              {
                year:2008,
                text:"李宁先生作为第二十九届北京奥运会主火炬手点燃圣火",
              },
              {
                year:2018,
                text:"登上纽约时装周，是首个亮相该时装周的中国运动品牌",
              },
              {
                year:2019,
                text:"在广西建立产品研发与生产的供应基地",
              },
              {
                year:2020,
                text:"李宁品牌三十周年主题活动“三十而立·丝路探行”在甘肃敦煌举办",
              },
              {
                year:2021,
                text:"东盟李宁中心项目正式启动，打造智能制造工业生态体系",
              },
              {
                year:2021,
                text:"“意想天开”中国李宁2022春夏潮流音乐节在三亚举行",
              },
              {
                year:2023,
                text:"在传承李宁原有文化的基础上，升级公司核心价值观",
              },
              {
                year:2023,
                text:"",
              },
            ]
            this.showLast=true
            this.isShowIcon=true
            if(this.$refs.container.scrollHeight){
              this.$refs.container.scrollTop = this.$refs.container.scrollHeight;
            }
          })
        }
        count ++
        let obj={}
        let obj2={}
        if(count === 0){
          obj={
            year:1990,
            text:"从广东三水起步，“李宁 牌”伴随第十一届亚运会 圣火传遍全国",
          }
          this.list.push(obj)
        }else if(count === 1){
          obj2={
            year:1992,
            text:"1992年起，连续四届成为奥运会中国代表团指定领奖装备合作伙伴",
          }
          this.list2.push(obj2)
        }
        else if(count === 2){
          obj={
            year:1993,
            text:'迁址至北京',
          }
          this.list.push(obj)
        }
        else if(count === 3){
          obj2={
            year:1998,
            text:"在广东佛山成立国内首家运动服装与鞋产品设计开发中心",
          }
          this.list2.push(obj2)
        }
        else if (count === 4){
          obj={
            year:2002,
            text:'确立全新品牌定位，提出“一切皆有可能”的品牌口号',
          }
          this.list.push(obj)
        }
        else if (count === 5){
          obj2={
            year:2008,
            text:"在香港联交所主板成功上市，成为内地首家在香港上市的体育用品公司",
          }
          this.list2.push(obj2)
        }
        else if (count === 6){
          obj={
            year:2008,
            text:"李宁先生作为第二十九届北京奥运会主火炬手点燃圣火",
          }
          this.list.push(obj)
        }
        else if (count === 7){
          obj2={
            year:2012,
            text:"成为CBA官方战略合作伙伴",
          }
          this.list2.push(obj2)
        }
        else if (count === 8){
          obj={
            year:2018,
            text:"登上纽约时装周，是首个亮相该时装周的中国运动品牌",
          }
          this.list.push(obj)
        }
        else if (count === 9){
          obj2={
            year:2018,
            text:"对外宣布采取“单品牌、多品类、多渠道”的发展策略",
          }
          this.list2.push(obj2)
        }
        else if (count === 10){
          obj={
            year:2019,
            text:"在广西建立产品研发与生产的供应基地",
          }
          this.list.push(obj)
        }
        else if (count === 11){
          obj2={
            year:2019,
            text:"李宁自主科技矩阵中革命性的一环“李宁䨻“轻弹科技平台正式发布",
          }
          this.list2.push(obj2)
        }
        else if (count === 12){
          obj={
            year:2020,
            text:"李宁品牌三十周年主题活动“三十而立·丝路探行”在甘肃敦煌举办",
          }
          this.list.push(obj)
        }
        else if (count === 13){
          obj2={
            year:2021,
            text:"“悟创吾意”中国李宁2021秋冬潮流发布举行，引发热议",
          }
          this.list2.push(obj2)
        }
        else if (count === 14){
          obj={
            year:2021,
            text:"东盟李宁中心项目正式启动，打造智能制造工业生态体系",
          }
          this.list.push(obj)
        }
        else if (count === 15){
          obj2={
            year:2021,
            text:"“悟·行”主题大秀在林芝举行",
          }
          this.list2.push(obj2)
        }
        else if (count === 16){
          obj={
            year:2021,
            text:"“意想天开”中国李宁2022春夏潮流音乐节在三亚举行",
          }
          this.list.push(obj)
        }
        else if (count === 17){
          obj={
            year:2022,
            text:"李宁飞电3ULTRA助力签约运动员获得柏林马拉松男子组第三名，中国运动品牌首登世界六大马拉松赛事领奖台",
          }
          this.list2.push(obj)
        }
        else if (count === 18){
          obj={
            year:2023,
            text:"在传承李宁原有文化的基础上，升级公司核心价值观",
          }
          this.list.push(obj)
        }
        else if (count === 19){
          obj={
            year:2023,
            text:"",
          }
          this.list.push(obj)
          this.showLast=true
        }
        this.$nextTick(() => {
          // 滚动到底部
          if(this.$refs.container.scrollHeight){
            this.$refs.container.scrollTop = this.$refs.container.scrollHeight;
          }
        });
        if (count >20) {
          setTimeout(()=>{
            this.isShowIcon=true
          },500)
          clearInterval(timer);
        }
      },1000);
    },
    goURL() {
      this.$router.push("/tuangouintroduce");
    }
  }
}
</script>
<style scoped>
.box{
  min-width: 335px;
  height: 100vh;
  overflow-y: auto;
  padding: 40px 20px 20px 20px;
  background-image: url("../assets/moduce-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.container{
  //max-height: 400px;
  //overflow-y: scroll;

}
.title{
  font-family: HYLingXinJ;
  font-weight: normal;
  font-size: 24px;
  color: #FFFFFF;
  text-align: left;
  letter-spacing: 2px;
  padding-top: 50px;
}
.dev-line{
  width: 30px;
  height: 2px;
  background: #fff;
  margin: 5px 0 20px 0;
}

.item-light{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  animation: zoom-in 1s forwards;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
}
@keyframes zoom-in {
  0% {
    transform: scale(0.5);
    opacity: 0;
    background-color: #666;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    background-color: #fff;
  }
}
.light-r{
  position: absolute;
  top: 0;
  left: -28px;
}
.light-l{
  position: absolute;
  top: -1px;
  right: -20px;
}
@keyframes slide-in {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;

  }
}
.item-text{
  font-family:FZLTHJW--GB1-0;
  margin-top: 4px;
  font-size: 13px;
  text-align: left;
  min-height: 50px;
  animation: slide-in 1.5s forwards;
  animation-delay: 0.5s;
  opacity: 0;
}
@keyframes fade-in-out {
  0% {
    color: #ddd;
    opacity: 0;
  }
  50% {
    color: #000;
    opacity: 1;
    font-weight: bold;
  }
  100% {
    color: #fff;
    font-weight: normal;
    opacity: 1;
  }
}
.text-last{
  color: #fff;
  margin-top: -45px;
  font-size: 13px;
  animation: slide-in 1.5s forwards;
  animation-delay: 0.5s;
  opacity: 0;
  font-family:FZLTHJW--GB1-0;
}
.year-line{
  width: 70%;
  border-bottom: 1px solid #fff;
  animation: fade-in-out 2s forwards;
  animation-delay: 1s;
  opacity: 0;
}
.year-num{
  font-family: HemiHeadEb-Italic;
  animation: fade-in-out 2s forwards;
  animation-delay: 1s;
  opacity: 0;
  font-size: 18px;
}
.dev-last{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.bot-img{
  width: 100px;
}
</style>
